import React, { useEffect } from "react";
import line from "../Assets/bg_img/underline.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Google_Map2({ google_map }) {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div
      className="mt-10 md:mt-16"
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
    >
      <div className="contact_map row-fluid no-padd margin-lg-115t margin-sm-50t no-padd relative box-border">
        <h2 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl font-merriweather text-center p-3 mt-8">
          A perfect location for your dream cottage
        </h2>
        <div className="flex flex-col-reverse md:flex-row justify-center items-center mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.0490906901196!2d79.67352231457781!3d29.39811995576963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3908d7f7740c0bc9%3A0xe9baaef9dd824df2!2sIndus%20Valley%20Sunderkhal%20Mukteshwar%20District%20Nainital!5e0!3m2!1sen!2sin!4v1636700789384!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            className="border-0 w-full md:w-[50%] h-[50vh]"
          ></iframe>
          <h3 className="w-fit text-[#74613C] mx-auto text-2xl md:text-4xl md:py-10 font-merriweather text-center p-3 mt-6 md:mt-0">
            GOOGLE LOCATION
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Google_Map2;
