import React from "react";
import SliderComponent from "./Slider/Slider";

import GalleryIv from "./GalleryIv";

const images = [
  { src: "https://d57jfigkzby0a.cloudfront.net/ov1.JPG", alt: "gallery-1" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov2.JPG", alt: "gallery-2" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov3.JPG", alt: "gallery-3" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov6.jpg", alt: "gallery-6" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov7.jpg", alt: "gallery-7" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov10.jpg", alt: "gallery-10" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov12.jpg", alt: "gallery-12" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov13.jpg", alt: "gallery-13" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov14.jpg", alt: "gallery-14" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov15.jpg", alt: "gallery-15" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov16.jpg", alt: "gallery-16" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov17.jpg", alt: "gallery-17" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov18.jpg", alt: "gallery-18" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov19.jpg", alt: "gallery-19" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov21.jpg", alt: "gallery-21" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov22.jpg", alt: "gallery-22" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov23.jpg", alt: "gallery-23" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov24.jpg", alt: "gallery-24" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov25.jpg", alt: "gallery-25" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov26.jpg", alt: "gallery-26" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov27.jpg", alt: "gallery-27" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov28.jpg", alt: "gallery-28" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov29.jpg", alt: "gallery-29" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov30.jpg", alt: "gallery-30" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov31.jpg", alt: "gallery-31" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov32.jpg", alt: "gallery-32" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov33.jpg", alt: "gallery-33" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov34.jpg", alt: "gallery-34" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov35.jpg", alt: "gallery-35" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov36.jpg", alt: "gallery-36" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov37.jpg", alt: "gallery-37" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov38.jpg", alt: "gallery-38" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov39.jpg", alt: "gallery-39" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov40.jpg", alt: "gallery-40" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov41.jpg", alt: "gallery-41" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov42.jpg", alt: "gallery-42" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov43.jpg", alt: "gallery-43" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov44.jpg", alt: "gallery-44" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov45.jpg", alt: "gallery-45" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov46.jpg", alt: "gallery-46" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov48.jpg", alt: "gallery-48" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov49.jpg", alt: "gallery-49" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov50.jpg", alt: "gallery-50" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov51.jpg", alt: "gallery-51" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov52.jpg", alt: "gallery-52" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov53.jpg", alt: "gallery-53" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov54.jpg", alt: "gallery-54" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov55.jpg", alt: "gallery-55" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov56.jpg", alt: "gallery-56" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov57.jpg", alt: "gallery-57" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov58.jpg", alt: "gallery-58" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov59.jpg", alt: "gallery-59" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov60.jpg", alt: "gallery-60" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov61.jpg", alt: "gallery-61" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov63.jpg", alt: "gallery-63" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov64.jpg", alt: "gallery-64" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov65.jpg", alt: "gallery-65" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov66.jpg", alt: "gallery-66" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov67.jpg", alt: "gallery-67" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov68.jpg", alt: "gallery-68" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov69.jpg", alt: "gallery-69" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov70.jpg", alt: "gallery-70" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov71.jpg", alt: "gallery-71" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov72.jpg", alt: "gallery-72" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov73.jpg", alt: "gallery-73" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov75.jpg", alt: "gallery-75" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov76.jpg", alt: "gallery-76" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov77.jpg", alt: "gallery-77" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov78.jpg", alt: "gallery-78" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov79.jpg", alt: "gallery-79" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov80.jpg", alt: "gallery-80" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov81.jpg", alt: "gallery-81" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov82.jpg", alt: "gallery-82" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov83.jpg", alt: "gallery-83" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov84.jpg", alt: "gallery-84" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov85.jpg", alt: "gallery-85" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov86.jpg", alt: "gallery-86" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov88.jpg", alt: "gallery-88" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov90.jpg", alt: "gallery-90" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov91.jpg", alt: "gallery-91" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov93.jpg", alt: "gallery-93" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov94.jpg", alt: "gallery-94" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov95.jpg", alt: "gallery-95" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov96.jpg", alt: "gallery-96" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov97.jpg", alt: "gallery-97" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov98.jpg", alt: "gallery-98" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov99.jpg", alt: "gallery-99" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov100.jpg", alt: "gallery-100" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov101.jpg", alt: "gallery-101" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov102.jpg", alt: "gallery-102" },
  { src: "https://d57jfigkzby0a.cloudfront.net/ov103.jpg", alt: "gallery-103" },
];

function Gallery() {
  return (
    <div>
      <div className="flex flex-col justify-center items-center py-5 bg-[#d2d2b4] bg-cover bg-opacity-60 text-[#40392d]">
        <div className="flex flex-col md:flex-row pt-5 lg:pt-10 justify-center items-center text-[#40392d]">
          <div className="w-fit mx-auto md:mx-0 text-2xl md:text-4xl font-merriweather text-center px-3 md:pl-0">
            ORIGINAL SITE PICTURES
          </div>
        </div>
      </div>

      <section className="flex flex-col md:p-5 md:pt-0 lg:p-10  bg-[#d2d2b4] bg-cover bg-opacity-80 text-[#40392d] ">
        <SliderComponent
          imageUrls={images.map((image) => image.src)}
          settings={{
            dots: false,
          }}
        />
      </section>

      <div className="py-10 md:py-0">
        <GalleryIv />
      </div>
    </div>
  );
}

export default Gallery;
