import React from "react";
import BlogCard from "./BlogCard";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const blogPost1 = {
    image: "https://d57jfigkzby0a.cloudfront.net/ivm-blog.png",
    title: "Mukteshwar: Best Place to Invest in Real Estate",
    date: "Sep 25, 2024",
    href: "/blog/best-hill-station-for-real-estate-investment",
  };
  const blogPost2= {
    image: "https://indusvalleymukteshwar.s3.eu-north-1.amazonaws.com/blog2.jpg",
    title: "Real Estate Investment Options and ROI in the Hill Stations",
    date: "Nov 11, 2024",
    href: "/blog/real-estate-investment-options",
  };


  return (
    <div
      className="flex flex-col gap-3 md:gap-10 px-5 md:px-20  bg-[#d2d2b4]"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <h1 className="w-fit mx-auto text-3xl md:text-5xl py-5 pt-16  text-center p-3">
        OUR BLOGS
      </h1>
      <Helmet>
        <title>Our Blogs</title>
        <meta
          name="description"
          content="Read our blogs to know more about the real estate industry, the future of smart real estate investment, and the journey of The Shubham Group."
        />
      </Helmet>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          alignSelf: "center",
        }}
        className="w-full md:w-[50%] flex flex-col md:flex-row gap-5"
      >
        <BlogCard
          image={blogPost1.image}
          title={blogPost1.title}
          date={blogPost1.date}
          href={blogPost1.href}
        />
        <BlogCard
          image={blogPost2.image}
          title={blogPost2.title}
          date={blogPost2.date}
          href={blogPost2.href}
        />
      </div>
    </div>
  );
};

export default Blogs;
